export enum SurveySetLanguageType{
    ENGLISH = "english",
    FRENCH = "français",
 } 
 export enum SurveySetLanguageCodeType{
    ENGLISH = "ENG",
    FRENCH = "FRE",
 } 

 export enum LanguageCodes{
    ENGLISH = "en-US",
    FRENCH  = "fr-FR"
 }
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { FontSizeTypes, ThemeTypes } from '../../enums/accessibilityTheme.enum';
import { SurveySetLanguageType } from '../../enums/surveyLanguage.enum';
import { SessionStorage } from '../../shared/utils/localStorageHelpers';

interface AccessibilityState {
    bigCursor: boolean;
    readAloud: boolean;
    selectedTheme: string;
    selectedFontSize: string;
    selectedSurveyLanguage:SurveySetLanguageType;
    currentRoute:string;
    currentQuestion:number;
    contentToRead:string;
    optionsLength:number
}

interface AccessibilityContextType extends AccessibilityState {
    updateAccessibilityState:(key:string, value:boolean | string | number)=>void
}

const initialAccessibilityState: AccessibilityState = {
    bigCursor: false,
    readAloud: false,
    selectedTheme: SessionStorage.getItem('THEME') ||  ThemeTypes.NORMAL,
    selectedFontSize: SessionStorage.getItem('ACCESSIBILITY_FONT') ||  FontSizeTypes.NORMAL,
    selectedSurveyLanguage:SurveySetLanguageType.ENGLISH,
    currentRoute:"/",
    currentQuestion:1,
    contentToRead:'',
    optionsLength:0
};

const AccessibilityContext = createContext<AccessibilityContextType | undefined>(undefined);

export const AccessibilityProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [accessibilityState, setAccessibilityState] = useState<AccessibilityState>(initialAccessibilityState);

    const updateAccessibilityState = (property:string, value:boolean | string | number) => {
        setAccessibilityState(prevState => ({ ...prevState, [property]: value }));
      };

    return (
        <AccessibilityContext.Provider value={{ ...accessibilityState, updateAccessibilityState }}>
            {children}
        </AccessibilityContext.Provider>
    );
};

export const useAccessibility = () => {
    const context = useContext(AccessibilityContext);
    if (!context) {
        throw new Error('useAccessibility must be used within an AccessibilityProvider');
    }
    return context;
};

import React, { FC, useState } from "react";

import { Col, Modal, Row } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import ImagePreviewStyles from "./imagePreview.module.scss";
import { AttachmentModel } from "../../../models/attachment.model";
import { backButtonIcon, closeIcon } from "../../../constants/icons";
import clsx from "clsx";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { useAccessibility } from "../../../context/AccessibilityContext";

interface ImagePreviewProps {
    visible: boolean;
    closeHandler: () => void;
    title?: string;
    attachment: AttachmentModel[];
}

const ImagePreview: FC<ImagePreviewProps> = (props) => {
    const { visible, closeHandler, title, attachment } = props;
    const { selectedTheme } = useAccessibility()

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const isPrevButtonDisabled = currentImageIndex === 0;
    const isNextButtonDisabled = currentImageIndex === attachment.length - 1;

    const showNextImage = () => {
        if (isNextButtonDisabled) return;
        setCurrentImageIndex(prev => prev +1);
    };

    const showPreviousImage = () => {
        if (isPrevButtonDisabled) return;
        setCurrentImageIndex(prev => prev -1);
    };
    const afterCloseHandler = () => setCurrentImageIndex(0)

    const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        closeHandler();
    };

    return (
        <Modal
            className={clsx(ImagePreviewStyles.imagePreviewModal, selectedTheme === ThemeTypes.DARK && ImagePreviewStyles.darkTheme, selectedTheme === ThemeTypes.INVERT && ImagePreviewStyles.invertTheme)}
            visible={visible}
            afterClose={afterCloseHandler}
            closable
            closeIcon={<i className={closeIcon}/>}
            footer={false}
            onCancel={handleCancel}
        >
            <div className={ImagePreviewStyles.imagePreviewModal__header}>
                <Row gutter={24}>
                    <Col className={ImagePreviewStyles.modalBack} span={24}>
                        <i onClick={closeHandler} className={backButtonIcon}></i>
                        <span className={ImagePreviewStyles.colorName}>{title}</span>
                    </Col>
                </Row>
            </div>
            <div className={ImagePreviewStyles.imagePreviewModal__image_section}>
                {attachment[currentImageIndex]?.s3Url ? (
                    <img
                        src={attachment[currentImageIndex]?.s3Url}
                        alt={attachment[currentImageIndex].fileName}
                    />
                ) : (
                    <h4 className={ImagePreviewStyles.noImageText}>Image unavailable !</h4>
                )}
            </div>
            <div className={ImagePreviewStyles.imagePreviewModal__controllers}>
                <div>
                    <div
                        className={clsx(ImagePreviewStyles.imagePreviewModal__controllersPrev, isPrevButtonDisabled && ImagePreviewStyles.disabledAction)
                        }
                        onClick={showPreviousImage}
                    >
                        <LeftOutlined />
                    </div>
                </div>
                <div>
                    <div
                        className={clsx(ImagePreviewStyles.imagePreviewModal__controllersNext, isNextButtonDisabled && ImagePreviewStyles.disabledAction)}
                        onClick={showNextImage}
                    >
                        <RightOutlined />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ImagePreview;

import { Card, Col, Row } from "antd";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import SelectSurveySetTypeStyles from "./selectSurveySetType.module.scss";
import Logo from "../../../../src/assets/images//nnas-logo.png";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import useRedirect from "../../../shared/utils/useRedirect";
import clsx from "clsx";
import Error from "../../../shared/components/Error"
import { selectTickIcon } from "../../../constants/icons";
import UserManagementService from "../../../services/UserManagementService/user.service";
import { UserAttempt } from "../../../models/user.model";
import MetaService, { MetaPayloadType } from "../../../services/MetaService/meta.service";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import { SurveySetsMeta } from "../../../models/survey.model";
import { SurveySteps } from "../../../enums/surveySteps.enum";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { SurveySetType } from "../../../enums/surveySet.enum";

type SurveyTypeMeta = MetaPayloadType<SurveySetsMeta>;

const SelectSurveySetType = () => {

  const {redirectToInstructions} = useRedirect()
  const {loading :submitting, userSurveyUpdate} = UserManagementService();
  const {loading, fetchMeta} = MetaService();
  const {selectedTheme, readAloud, updateAccessibilityState} = useAccessibility()

  const formikRef = useRef<FormikProps<UserAttempt>>(null);

  const [selectedSetTypeLanguage, setSelectedSetTypeLanguage] = useState<number>();
  const [surveySetsMeta, setSurveySetsMeta] = useState<SurveySetsMeta[]>([]);

  useEffect(()=>{
    handleFetchMeta();
  },[])
  
  const handleSubmit = async(values:UserAttempt) => {
    if (values.surveySetId === undefined) {
      formikRef.current?.setFieldError("surveySetId", "Please select a Survey Set to proceed");
      return;
    }
    const payload = {step: SurveySteps.INSTRUCTIONS_PAGE,surveySetId: values.surveySetId}
    const response = await userSurveyUpdate(payload);
    if(!response) return;
    redirectToInstructions()
  };

  const handleFetchMeta = async() => 
    await fetchMeta({url:ApiRoutes.SURVEY_SETS_META,responseKey:"survey_sets", setState:setSurveySetsMeta, model:SurveySetsMeta} as SurveyTypeMeta);
  

  const handleSelectLanguage = (id?:number) => () => {
    setSelectedSetTypeLanguage(id);
    formikRef.current?.setFieldValue('surveySetId',id);
  }


  return (
    <div className={SelectSurveySetTypeStyles.setTypeContainer}>
      <Row className={SelectSurveySetTypeStyles.content}>
        {/* Left Column */}
        <Col xs={24} sm={24} md={12} lg={10} xl={12}>
          <div>
            <img
              src={Logo}
              alt="Logo"
              className={SelectSurveySetTypeStyles.logo}
              data-content-to-read='Logo'
            />
            <h1 className={SelectSurveySetTypeStyles.setTypeLogoText} data-content-to-read='The starting point for Internationally Educated Nurses interested in becoming licensed or registered as a nurse in Canada.'>
              The starting point for Internationally Educated Nurses
              interested in becoming licensed or registered as a nurse in
              Canada.
            </h1>
          </div>
        </Col>

        {/* Right Column */}
        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
          <Card className={clsx(SelectSurveySetTypeStyles.setTypeCard, selectedTheme === ThemeTypes.DARK && SelectSurveySetTypeStyles.darkTheme, selectedTheme === ThemeTypes.INVERT && SelectSurveySetTypeStyles.invertTheme)} >
            <div className={SelectSurveySetTypeStyles.cardContainer} >
              <h1 className={SelectSurveySetTypeStyles.setTypeText} data-content-to-read='Select Survey Set'>Select Survey Set</h1>
              {/* Formik Form */}
              <Formik
                initialValues={{ surveySetId: selectedSetTypeLanguage }}
                innerRef={formikRef}
                onSubmit={handleSubmit}>
                <Form>
                  <div className={clsx(SelectSurveySetTypeStyles.setTypeContainer)}>
                  <div className={SelectSurveySetTypeStyles.setTypeCardWrapper}>
                            {surveySetsMeta?.map((setType, index) =>(
                            <div className={clsx(SelectSurveySetTypeStyles.setTypeCard, selectedSetTypeLanguage === setType.id && SelectSurveySetTypeStyles.selected)} key={setType.id} onClick={handleSelectLanguage(setType.id)} data-content-to-read={setType.setType ? setType.setType === SurveySetType.STANDARD ? "Regular Option" : setType.setType + "Option" : ""} >
                                <i className={selectTickIcon}/>
                                <div className={SelectSurveySetTypeStyles.textContainer} tabIndex={index + 12} data-content-to-read={setType.setType ? setType.setType === SurveySetType.STANDARD ? "Regular Option" : setType.setType + "Option" : ""} >
                                <div className={SelectSurveySetTypeStyles.setTypeCardText} data-content-to-read={setType.setType ? setType.setType === SurveySetType.STANDARD ? "Regular Option" : setType.setType + "Option" : ""}>{setType.setType === SurveySetType.STANDARD ? 'Regular' : setType.setType}</div>
                                </div>
                            </div>
                            ))}
                        </div>
                        <ErrorMessage name='surveySetId'>
                          {(message: string) => <Error message={message} />}
                        </ErrorMessage>
                    </div>
                  <div className={SelectSurveySetTypeStyles.buttonContainer}>
                    <ButtonComponent
                      primary
                      htmlType="submit"
                      className={SelectSurveySetTypeStyles.submitButton}
                      content="Continue"
                      loading = {loading || submitting}
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SelectSurveySetType;

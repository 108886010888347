import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from 'antd';
import Error from "../Error";
import InputStyles from "./inputField.module.scss"
import clsx from "clsx";

interface InputFieldProps {
    type: string;
    name: string;
    placeholder: string;
    label?:string;
    className?:string
}

const InputField: FC<InputFieldProps> = (props) => {
    const { name,label, className } = props;
    return (
        <div>
            <label>{label}</label>
            <Field as={Input} {...props} className={clsx(className && InputStyles.className)}/>
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} data-content-to-read={message} />}
            </ErrorMessage>
        </div>
    )
}

export default InputField;
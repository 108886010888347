import React, { FC } from 'react'
import { Skeleton } from 'antd'

interface SkeltonProps{
    className?:string;
    rows?:number
}

const SkeltonLoader:FC<SkeltonProps> = ({className,rows=4}) => <Skeleton active className={className} paragraph={{rows:rows}}/>

export default SkeltonLoader
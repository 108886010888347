export enum ThemeTypes{
    NORMAL = "normal",
    DARK = "dark",
    INVERT = "invert colors"
 } 

 export enum FontSizeTypes{
    SMALL = "small",
    NORMAL = "normal",
    LARGE = "large"
 }
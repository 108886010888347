import React, { useState } from 'react';
import { Switch, Checkbox, Popover } from 'antd';
import { accessibilityIcon, Logo, selectTickIcon } from '../../../constants/icons';
import AccessibilityStyles from "./accessibility.module.scss"
import { FontSizeTypes, ThemeTypes } from '../../../enums/accessibilityTheme.enum';
import clsx from 'clsx';
import { useAccessibility } from '../../../context/AccessibilityContext';
import ButtonComponent from '../ButtonComponent';
import QuestionManagementService from '../../../services/QuestionService/question.service';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import { Link } from 'react-router-dom';
import { SessionStorage } from '../../utils/localStorageHelpers';

const AccessibilityPopup = () => {

    const { bigCursor, updateAccessibilityState, readAloud, selectedTheme, selectedFontSize, currentRoute} = useAccessibility();
    const {loading, handleDownloadReport } = QuestionManagementService();
    const handleBigCursorChange = (checked: boolean) => {
        updateAccessibilityState('bigCursor',checked);
        SessionStorage.setItem('BIG_CURSOR',checked)
    };

    const handleReadAloudChange = (checked: boolean) => {
        updateAccessibilityState('readAloud',checked);
        SessionStorage.setItem('READ_ALOUD',checked)
    };

    const handleSelectSetType = (theme: ThemeTypes) => () => {
        updateAccessibilityState('selectedTheme',theme);
        SessionStorage.setItem('THEME',theme)
    }

    const handleSelectFontSize = (font: FontSizeTypes) => () => {
        const fontSize = font === FontSizeTypes.SMALL ? "58.5%" 
                        : font === FontSizeTypes.NORMAL ? "68.5%" 
                        : "80%";
        document.documentElement.style.setProperty(
            "--font-size",
            fontSize,
        )
        updateAccessibilityState('selectedFontSize',font);
        SessionStorage.setItem('ACCESSIBILITY_FONT',font)
    }

    const accessibilityContent =
        <div className={clsx(AccessibilityStyles.contentContainer, currentRoute === AppRoutes.REPORT && AccessibilityStyles.header, selectedTheme === ThemeTypes.INVERT && AccessibilityStyles.invertTheme, selectedTheme === ThemeTypes.DARK && AccessibilityStyles.darkTheme)}>
            <div className={AccessibilityStyles.title}>
                Accessibility
            </div>
            <div className={AccessibilityStyles.textSizeContainer}>
                <div className={AccessibilityStyles.fontItemsWrapper}>
                    {Object.values(FontSizeTypes).map((fontType, index) => (
                        <div tabIndex={index + 2} data-content-to-read={fontType + "Font type"} className={clsx( SessionStorage.getItem('ACCESSIBILITY_FONT') === fontType || selectedFontSize === fontType ? AccessibilityStyles.selected : AccessibilityStyles.fontItems)} key={fontType} onClick={handleSelectFontSize(fontType)}>
                            <div className={AccessibilityStyles.fontType} >
                                <i className={selectTickIcon} />
                            </div>
                            <div className={AccessibilityStyles[`${"item" + index}`]}>A</div>
                        </div>
                    ))}
                </div>
                <div className={AccessibilityStyles.typeTitle}>
                    Text size
                </div>
            </div>
            <div className={AccessibilityStyles.cursorContainer}  tabIndex={5} data-content-to-read='Big Cursor Option'>
                <Checkbox checked={SessionStorage.getItem('BIG_CURSOR') || bigCursor} onChange={e => handleBigCursorChange(e.target.checked)}>
                    Big Cursor
                </Checkbox>
            </div>
            <div className={AccessibilityStyles.readAloudContainer} tabIndex={6} data-content-to-read='Read aloud option'>
                <span className={AccessibilityStyles.readAloudLabel}>Read Aloud</span>
                {/* Temporarily Disabling the Read Aloud Feature for current deployment  */}
                <Switch checked={SessionStorage.getItem('READ_ALOUD') || readAloud} onChange={handleReadAloudChange} checkedChildren="ON" unCheckedChildren="OFF" />
            </div>
            <div className={AccessibilityStyles.themeContainer}>
                <div className={AccessibilityStyles.themeItemsWrapper}>
                    {Object.values(ThemeTypes).map((theme,index) => (
                        <div tabIndex={index + 7} data-content-to-read={theme + "theme type"} className={clsx(SessionStorage.getItem('THEME') === theme || selectedTheme === theme ? AccessibilityStyles.selected : AccessibilityStyles.themeItems)} key={theme} onClick={handleSelectSetType(theme)}>
                            <div className={AccessibilityStyles.themeType} >
                                <i className={selectTickIcon} />
                            </div>
                            <div className={AccessibilityStyles.themeText}>{theme}</div>
                        </div>
                    ))}
                </div>
                <div className={AccessibilityStyles.typeTitle}>
                    Colour Contrast
                </div>
            </div>
        </div>

    const  isLogoNeeded = currentRoute === AppRoutes.QUESTIONCARD_SPECIFIC || currentRoute === AppRoutes.QUESTIONCARD || currentRoute === AppRoutes.REPORT || currentRoute === AppRoutes.REVIEW_AND_SUBMIT

    return (
        <div className={clsx(isLogoNeeded ? AccessibilityStyles.accessibilityContainer : AccessibilityStyles.accessibilityIconContainer, currentRoute === AppRoutes.REPORT && AccessibilityStyles.headerContainer, selectedTheme === ThemeTypes.INVERT && AccessibilityStyles.invertThemeheaderContainer, selectedTheme === ThemeTypes.DARK && AccessibilityStyles.darkThemeheaderContainer )}>
           { isLogoNeeded ? <div>
                <img
                    src={Logo}
                    alt="Logo"
                    className={AccessibilityStyles.logo}
                    data-content-to-read = 'Logo'
                />
            </div> : null}
            <div className={AccessibilityStyles.popOverContainer}>
                <Popover
                    content={accessibilityContent}
                    placement="left"
                    trigger="click"
                    overlayClassName={clsx(selectedTheme === ThemeTypes.INVERT && AccessibilityStyles.invertThemeContainer, selectedTheme === ThemeTypes.DARK && AccessibilityStyles.darkThemeContainer)}
                    
                >
                    <div className={AccessibilityStyles.iconContainer}>
                        <div className={AccessibilityStyles.accessbilityIconWrapper} tabIndex={1} data-content-to-read="Accessibility Icon">
                            <i className={accessibilityIcon} />
                        </div>
                    </div>
                </Popover>
                {currentRoute === AppRoutes.REPORT && <div className={AccessibilityStyles.button}>
                    <ButtonComponent
                        primary
                        content="Download Report"
                        loading={loading}
                        clickHandler={handleDownloadReport}
                    />
                </div>}
            </div>
        </div>
    );
};

export default AccessibilityPopup;

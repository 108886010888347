
export enum AnswerTypeEnum {
    SINGLE_SELECT = 'single',
    SINGLE_SELECT_COUNTRY = 'single_country_select',
    MULTI_SELECT = 'multi'
}

export enum AnswerResourceType {
    OPTION = 'Option',
    COUNTRY = 'Country'
}

import React from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import AppComponents from "../views/AppComponents";
import InstructionCard from "../views/Home/InstructionsCard";
import LandingEmail from "../views/Home/LandingEmail";
import MainLayout from "../views/Home/MainLayout";
import QuestionComponent from "../views/Home/QuestionComponent";
import SelectLanguage from "../views/Home/SelectLanguage";
import SelectSurveySetType from "../views/Home/SelectSurveyType";
import SurveyReport from "../views/Home/SurveyReport";
import { AppRoutes } from "./routeConstants/appRoutes";
import ReviewComponent from "../views/Home/ReviewComponent";
import isAllowed from "../shared/components/HOC/requireAuth";
import { SurveySteps } from "../enums/surveySteps.enum";
 
const AppRouter = () => {
  const routes: RouteObject[] = [
    {
      path: AppRoutes.APP_COMPONENTS,
      element: <AppComponents/>,
    },
    {
      path:AppRoutes.LAYOUT,
      element:<MainLayout/>,
      children:[
        {
          path:AppRoutes.WELCOME_EMAIL,
          element:isAllowed(
            <LandingEmail/>,
            SurveySteps.LANDING_EMAIL, 
          )
        },
        {
          path:AppRoutes.INSTRUCTIONS,
          element:isAllowed(
            <InstructionCard/>,
            SurveySteps.INSTRUCTIONS_PAGE, 
          )
        },
        {
          path:AppRoutes.QUESTIONCARD,
          element:isAllowed(
            <QuestionComponent/>,
            SurveySteps.SURVEY_STARTED, 
          )
        },
        {
          path:AppRoutes.QUESTIONCARD_SPECIFIC,
          element:isAllowed(
            <QuestionComponent/>,
            SurveySteps.REVIEW_AND_SUBMIT, 
          )
        },
        {
          path:AppRoutes.REPORT,
          element:isAllowed(
            <SurveyReport/>,
            SurveySteps.VIEW_REPORT, 
          )
        },
        {
          path:AppRoutes.SELECT_LANGUAGE,
          element:isAllowed(
            <SelectLanguage />,
            SurveySteps.SELECT_LANGUAGE, 
          )
        },
        {
          path:AppRoutes.SELECT_SURVEY_SET_TYPE,
          element:isAllowed(
            <SelectSurveySetType/>,
            SurveySteps.SELECT_SURVEY_SET, 
          )
        },
        {
          path:AppRoutes.REVIEW_AND_SUBMIT,
          element:isAllowed(
            <ReviewComponent/>,
            SurveySteps.REVIEW_AND_SUBMIT, 
          )
        },
      ]
    }
  ];
 
  if (Boolean(process.env.REACT_APP_UNDER_DEVELOPMENT)) {
    routes.push({
      path: AppRoutes.APP_COMPONENTS,
      element: <AppComponents />,
    });
  }
 
  return useRoutes(routes);
};
 
export default AppRouter;
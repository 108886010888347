import { Dispatch, SetStateAction, useState } from "react";
import { ClazzOrModelSchema, deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { PaginationModel } from "../../models/pagination.meta.model";

export type MetaPayloadType<Type> = {
  url: string
  responseKey: string
  setState: React.Dispatch<React.SetStateAction<Type[] | undefined>>,
  model: Type;
  params?: string
  scrollDetails?: {
    shouldUpdate?: boolean;
    setPagination?: Dispatch<SetStateAction<PaginationModel | undefined>>;
  }
};

const MetaService = () => {
    const [loading, setLoading] = useState<boolean>(false);
  
    const fetchMeta = async <Type>(
      payload: MetaPayloadType<Type>
    ) => {
      const { url, responseKey, setState, params, model, scrollDetails } = payload;
      try {
        setLoading(true);
        const response = await axiosInstance.get(url, { params });
        let data: Type[];
        if (model) {
          data = deserialize(model as ClazzOrModelSchema<Type[]>, response.data[responseKey]);
        } else {
          data = response.data[responseKey] as Type[];
        }
        if (scrollDetails?.shouldUpdate) {
          const pagination = deserialize(PaginationModel, response.data["meta"]);
          setState((prev) => [...prev ?? [], ...data]);
          scrollDetails?.setPagination &&
            scrollDetails?.setPagination(pagination);
        } else setState(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return{
        loading,
        fetchMeta,
    }
};

export default MetaService;
type KEY = "ACCESS_TOKEN" | "REFRESH_TOKEN" | "USER" | "EMAIL" | 'TOKEN' | 'SESSION_TOKEN' | 'ACCESSIBILITY_FONT' | 'BIG_CURSOR' | 'READ_ALOUD' | 'THEME' | 'SELECTED_LANGUAGE';

export class SessionStorage {
    static setItem<T>(key: KEY, value:T) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    static getItem<T>(key: KEY):T | null {
        const data = sessionStorage.getItem(key);
        if (data && data !== "undefined") {
            return JSON.parse(data);
        }
        return null
    }

    static removeItem(key: KEY) {
        sessionStorage.removeItem(key);
    }

    static clear() {
        sessionStorage.clear();
    }
}
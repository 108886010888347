import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { User, UserAttempt } from "../../models/user.model";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import { SurveySteps } from "../../enums/surveySteps.enum";
import { SessionStorage } from "../../shared/utils/localStorageHelpers";

const UserManagementService = () => {

	const [error, setError] = useState<Error | unknown>();

	const [loading, setLoading] = useState(false);

	const { setAuthenticated, setStep, updateCurrentQuestion } = AuthContext();

	const emailManagement = async (data: User) => {
		try {
		  setLoading(true);
		  const response = await axiosInstance.post(ApiRoutes.USER_MANAGEMENT, { users: serialize(User, data) });
		  const user = deserialize(User, response.data["user"]);
		  setAuthenticated(user?.token);
		  await userSurveyUpdate({step: SurveySteps.SELECT_LANGUAGE});
		} catch (error) {
		  setError(error);
		} finally {
		  setLoading(false);
		}
	  };

	const userSurveyUpdate = async(params?:{step?: SurveySteps, surveySetId?:number, languageId?:number, currentQuestionId?: number }) => {
		try {
			setLoading(true);
			const payload = params && serialize(UserAttempt, params)
			const response = await axiosInstance.put(ApiRoutes.UPDATE_USER_ATTEMPT,{user_attempt: payload});
			params?.step && setStep(params?.step);
			return true;
		  } catch (error) {
			setError(error);
		  } finally {
			setLoading(false);
		  }
	}

	const getUserAttempt = async() => {
		try {
			setLoading(true);
			const response = await axiosInstance.get(ApiRoutes.UPDATE_USER_ATTEMPT);
			setStep(response.data["user_attempt"].step);
			return true;
		  } catch (error) {
			setError(error);
		  } finally {
			setLoading(false);
		  }
	}

	const startUserSurvey = async() => {
		try {
			setLoading(true);
			const response = await axiosInstance.put(ApiRoutes.START_SURVEY);
			updateCurrentQuestion(true);
			await userSurveyUpdate({step: SurveySteps.SURVEY_STARTED});
			return true;
		  } catch (error) {
			setError(error);
		  } finally {
			setLoading(false);
		  }
	}

	const endUserSurvey = async() => {
		try {
			setLoading(true);
			const response = await axiosInstance.put(ApiRoutes.END_SURVEY);
			return true;
		  } catch (error) {
			setError(error);
		  } finally {
			setLoading(false);
		  }
	}

	const userVisited = async() => {
		try {
			setLoading(true);
			const response = await axiosInstance.post(ApiRoutes.USER_VISIT);
			SessionStorage.setItem('SESSION_TOKEN', response.data["session_token"]) ;
			return true;
		  } catch (error) {
			setError(error);
		  } finally {
			setLoading(false);
		  }
	}

	return {
		error,
		loading,
		emailManagement,
		userSurveyUpdate,
		startUserSurvey,
		endUserSurvey,
		getUserAttempt,
		userVisited
	};
};

export default UserManagementService;

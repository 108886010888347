import {serializable, alias, primitive} from 'serializr';

export class Survey {
    @serializable
    language?: string;
}

export class SurveySetsMeta {

    @serializable
    id?: number;

    @serializable(alias('set_type', primitive()))
    setType?: string;
}

export class LanguagesMeta {

    @serializable
    id?: number;

    @serializable
    name?: string;

    @serializable
    code?: string;
}
import React, { FC, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import PdfStyles from "./pdfViewer.module.scss"
import SkeltonLoader from "../SkeltonLoader";
import { useAccessibility } from "../../../context/AccessibilityContext";
import clsx from "clsx";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
 
interface PDFViewerProps {
  pdfUrl: string;
}
 
const PdfViewer: FC<PDFViewerProps> = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState<number>();
  const { updateAccessibilityState, selectedTheme } = useAccessibility();
  const [loading, setLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setLoading(false);
  }

  const handleOnLoadError = ()=> {
    setLoading(false);
    Notification({
      message: "",
      description: "Failed Load the PDF file",
      type: NotificationTypes.ERROR,
    });
  }
  // Note: For read aloud the total content of report
  // const fetchPdfText = async () => {
  // const response = await fetch(pdfUrl);
  //     const buffer = await response.arrayBuffer();
  //     const pdf = await pdfjs.getDocument({ data: buffer }).promise;
  //     const fullText = Array.from({ length: pdf.numPages }, async (_, i) => {
  //       const page = await pdf.getPage(i + 1);
  //       const pageTextContent = await page.getTextContent();
  //       return pageTextContent.items.reduce((acc, textItem) => {
  //           if ('str' in textItem) {
  //               acc += textItem.str + ' ';
  //           }
  //           return acc;
  //       }, '');
  //   }).reduce((acc, pageText) => acc + pageText, '');
  //   updateAccessibilityState('contentToRead',fullText);
  //     setNumPages(pdf.numPages);
  //   };

  //   useEffect(()=>{
  //     fetchPdfText()
  //   },[pdfUrl])

    
  return (
    <div className={PdfStyles.container} >
      <Document
      file={pdfUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      className={clsx(PdfStyles.document, selectedTheme === ThemeTypes.INVERT && PdfStyles.invertTheme)}
      onLoadError={handleOnLoadError}
      loading={<SkeltonLoader
        className={PdfStyles.loading}
        rows={10}
      /> }
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={true}
          className={PdfStyles.page}
          scale={2}
          width={480}
        />
      ))}
    </Document>
    </div>
  );
};
 
export default PdfViewer;
 
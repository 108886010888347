import {serializable, alias, primitive} from 'serializr';
import { SurveySteps } from '../enums/surveySteps.enum';

export class User {
    @serializable
    email?: string;

    @serializable(alias('is_email', primitive()))
    isEmail?: boolean;

    @serializable
    token?: string;

    @serializable
    role?: string;
}

export class UserAttempt {

    @serializable
    step?: SurveySteps;

    @serializable(alias('survey_set_id', primitive()))
    surveySetId?: number;

    @serializable(alias('language_id', primitive()))
    languageId?: number;

    @serializable(alias('current_question_id', primitive()))
    currentQuestionId?: number;
}

import React from "react";
import { Card as AntdCard, CardProps } from "antd";
import clsx from "clsx";
import CardStyles from "./cardComponent.module.scss";
import ButtonComponent from "../ButtonComponent";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";

interface CardComponentProps extends CardProps {
  isButton?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonSize?: SizeType;
  buttonLoading?: boolean;
}

const Card: React.FC<CardComponentProps> = ({
  title,
  children,
  buttonText,
  onButtonClick,
  isButton,
  size,
  className,
  buttonSize,
  buttonLoading,
}) => {
  const {selectedTheme} = useAccessibility()
  return (
    <div className={clsx( selectedTheme === ThemeTypes.DARK && CardStyles.darkTheme)}>
    <AntdCard
      title={title}
      className={clsx(size && size, className, CardStyles.card, selectedTheme === ThemeTypes.INVERT && CardStyles.invertTheme)}
    >
      {children}
      {isButton && (
        <ButtonComponent
          primary
          clickHandler={onButtonClick}
          content={buttonText}
          size={buttonSize}
          className={CardStyles.button}
          loading={buttonLoading}
        />
      )}
    </AntdCard>
    </div>
  );
};

export default Card;

import React, { useEffect, useRef, useState } from 'react';
import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import AccessibilityPopup from '../../../shared/components/Accessibility';
import clsx from 'clsx';
import { useAccessibility } from '../../../context/AccessibilityContext';
import MainLayoutStyles from "./mainLayout.module.scss"
import { FontSizeTypes, ThemeTypes } from '../../../enums/accessibilityTheme.enum';
import { LanguageCodes, SurveySetLanguageType } from '../../../enums/surveyLanguage.enum';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import ReviewCardStyles from "../ReviewComponent/reviewComponent.module.scss";
import { ElementTypes, KeyTypes } from '../../../enums/elementType.enum';
import { SessionStorage } from '../../../shared/utils/localStorageHelpers';
import { FontSizePercentageTypes, FontSizeTitle } from '../../../enums/fontSize.enum';

const MainLayout: React.FC = () => {
  const { bigCursor, readAloud, selectedTheme, selectedFontSize, currentQuestion, selectedSurveyLanguage, updateAccessibilityState, contentToRead } = useAccessibility();
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null)
  const activeElementRef = useRef<HTMLImageElement | HTMLHeadingElement | HTMLElement | null>(null);

  // to presisit the selectedAccessbilityValue after refresh
  const isBigCursor: boolean = SessionStorage.getItem('BIG_CURSOR') || false
  const theme = SessionStorage.getItem('THEME') || ThemeTypes.NORMAL
  const isReadAloud = SessionStorage.getItem('READ_ALOUD') || false

  useEffect(() => {
    // Clean up the previous utterance if any
    window.speechSynthesis.cancel();

    // Read the content aloud when the component mounts or the location changes
    readContentAloud();

    // Clean up the speech synthesis when the component unmounts or location changes
    return () => {
      window.speechSynthesis.cancel();
    };
  }, [contentToRead, readAloud, selectedSurveyLanguage, isReadAloud]);

  const removeActiveClass = () => activeElementRef?.current?.classList.remove('activeElement');

  const handleMouseDoubleClick = (event: MouseEvent) => {
    event.preventDefault();
    if ((readAloud || isReadAloud) && location.pathname !== AppRoutes.REVIEW_AND_SUBMIT) {
      if (activeElementRef.current) {
        removeActiveClass();
      }
      const target = event.target as HTMLImageElement | HTMLHeadingElement;
      if(target === document.body && location?.pathname === AppRoutes.REPORT) return ;
      if (location.pathname[0] === AppRoutes.QUESTIONCARD[0] || location.pathname[0] === AppRoutes.QUESTIONCARD_SPECIFIC[0]) {
        const contentValue = target.getAttribute('data-content-to-read') || '';
        updateAccessibilityState('contentToRead', target?.textContent || contentValue);
      } else {
        const contentValue = target.getAttribute('data-content-to-read') || '';
        updateAccessibilityState('contentToRead', contentValue);
      }
      if (target instanceof Element) {
        target.classList.add('activeElement');
        activeElementRef.current = target;
      }
    }
  };

  const handleTabPress = (event: KeyboardEvent) => {
    if (event.key === KeyTypes.TAB) {
      if (readAloud || isReadAloud) {
        removeActiveClass();
        const focusedElement = document.activeElement as HTMLElement;
        let contentValue = '';
        if (focusedElement.tagName.toLowerCase() === ElementTypes.BUTTON || focusedElement.tagName.toLowerCase() === ElementTypes.INPUT) {
          const spanElement = focusedElement.querySelector('span');
          if (spanElement) {
            contentValue = spanElement.textContent + " " + ElementTypes.BUTTON || '';
          } else {
            const inputContent = focusedElement.getAttribute('data-content-to-read') || '';
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = inputContent;
            contentValue = tempDiv.innerText || "Dropdown" || '';
          }
        } else {
          // If it's not a button
          const isQuestionOptions = focusedElement.getAttribute('data-question-options')
          if (isQuestionOptions) {
            contentValue = focusedElement.getAttribute('data-content-to-read')?.replace(/<\/?p>/g, '') ?? focusedElement?.innerText ?? '';
          } else {
            contentValue = focusedElement.getAttribute('data-content-to-read') ?? focusedElement?.innerText ?? '';
          }
        }
        updateAccessibilityState('contentToRead', contentValue);
        if (focusedElement instanceof Element) {
          focusedElement.classList.add('activeElement');
          activeElementRef.current = focusedElement;
        }
      }
    }
    else if (event.key === KeyTypes.ARROW_UP || event.key === KeyTypes.ARROW_DOWN) {
      if (readAloud || isReadAloud) {
        const activeElements = document.querySelector('.ant-select-item-option-active')
        if (activeElements) {
          // Get the value of the 'title' attribute
          const titleAttributeValue = activeElements.getAttribute('title');

          updateAccessibilityState('contentToRead', titleAttributeValue ? titleAttributeValue : "")
          activeElements.className += " " + 'activeElement';
        }
      }
    }
  };

  useEffect(() => {
    // to presisit the selectedAccessbilityValue after refresh
    const fontSize = selectedFontSize === FontSizeTypes.SMALL ? FontSizePercentageTypes.SMALL
      : selectedFontSize === FontSizeTypes.NORMAL || selectedFontSize === FontSizeTypes.NORMAL ? FontSizePercentageTypes.NORMAL
        : FontSizePercentageTypes.LARGE;
    document.documentElement.style.setProperty(
      FontSizeTitle.FONT_SIZE,
      fontSize,
    )
    document.addEventListener('dblclick', handleMouseDoubleClick);
    document.addEventListener('keyup', handleTabPress);

    return () => {
      document.removeEventListener('dblclick', handleMouseDoubleClick);
      document.removeEventListener('keyup', handleTabPress);
    };
  }, [readAloud, isReadAloud]);

  // Note: if they want total read of content ill uncomment this
  // useEffect(()=>{
  //   if(location?.pathname !== AppRoutes.QUESTIONCARD || location?.pathname !== AppRoutes.REPORT || location?.pathname !== AppRoutes.REVIEW_AND_SUBMIT){
  //     const content = contentRef?.current?.innerText;
  //     content && updateAccessibilityState('contentToRead',content);
  //   }
  //   updateAccessibilityState('currentRoute',location.pathname)
  // },[location, currentQuestion])

  useEffect(() => {
    //while changing location read aloud content is making to null 
    updateAccessibilityState('contentToRead', '');
    updateAccessibilityState('currentRoute', location.pathname)
    if (activeElementRef.current) {
      removeActiveClass();
    }
  }, [location, currentQuestion])

  // Function to read the content aloud
  const readContentAloud = () => {
    // Get the content to be read aloud (you might need to adjust this based on your Outlet's structure)
    if (contentToRead && (readAloud || isReadAloud)) {
      const utterance = new SpeechSynthesisUtterance(contentToRead);
      utterance.lang = selectedSurveyLanguage === SurveySetLanguageType.ENGLISH ? LanguageCodes.ENGLISH : LanguageCodes.FRENCH; // Set the language to English
      utterance.rate = 0.9;
      window.speechSynthesis.speak(utterance);
      utterance.onend = function (event) {
        if (activeElementRef.current) {
          removeActiveClass();
        }
        if (location.pathname === AppRoutes.REVIEW_AND_SUBMIT) {
          const activeElements = document.getElementsByClassName(ReviewCardStyles.active)
          const elementsArray = Array.from(activeElements);
          elementsArray?.forEach(element => {
            element.classList.remove(ReviewCardStyles.active);
          });
        }
        updateAccessibilityState('contentToRead', '');
      };
    }
  };


  return (
    <Layout className={clsx((selectedTheme === ThemeTypes.INVERT || theme === ThemeTypes.INVERT) && MainLayoutStyles.invertTheme, (selectedTheme === ThemeTypes.DARK || theme === ThemeTypes.DARK) && MainLayoutStyles.darkTheme, MainLayoutStyles.mainWrapper, (bigCursor || isBigCursor) && MainLayoutStyles.bigCursor)}>
      <AccessibilityPopup />
      <div ref={contentRef} className={MainLayoutStyles.mainContent}>
        <Outlet />
      </div>
    </Layout>
  );
};

export default MainLayout;
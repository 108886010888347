import { SurveySteps } from "../../enums/surveySteps.enum";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";

export const steps = [
    {
        step: SurveySteps.LANDING_EMAIL,
        allowedRoutes: [AppRoutes.WELCOME_EMAIL],
    },
    {
        step: SurveySteps.SELECT_LANGUAGE,
        allowedRoutes: [AppRoutes.SELECT_LANGUAGE],
    },
    {
        step: SurveySteps.SELECT_SURVEY_SET,
        allowedRoutes: [AppRoutes.SELECT_SURVEY_SET_TYPE],
    },
    {
        step: SurveySteps.INSTRUCTIONS_PAGE,
        allowedRoutes: [AppRoutes.INSTRUCTIONS],
    },
    {
        step: SurveySteps.SURVEY_STARTED,
        allowedRoutes: [AppRoutes.QUESTIONCARD],
    },
    {
        step: SurveySteps.REVIEW_AND_SUBMIT,
        allowedRoutes: [AppRoutes.REVIEW_AND_SUBMIT, AppRoutes.QUESTIONCARD_SPECIFIC],
    },
    {
        step: SurveySteps.VIEW_REPORT,
        allowedRoutes: [AppRoutes.REPORT],
    },
]
import React from "react";
import AppRoutes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import "./styles/_main.scss";
import RequireNetwork from "./shared/components/HOC/requireNetwork";
import { BrowserRouter } from "react-router-dom";
import { AccessibilityProvider } from "./context/AccessibilityContext";

const App = () => {
  
  return (
    <RequireNetwork>
      <AuthProvider>
        <AccessibilityProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AccessibilityProvider>
      </AuthProvider>
    </RequireNetwork>
  );
};

export default App;

import { alias, primitive, serializable } from "serializr";

export class PaginationModel {

    @serializable(alias('current_page', primitive()))
    currentPage?: number;

    @serializable(alias('total_pages', primitive()))
    totalPages?: number;

    @serializable(alias('total_count', primitive()))
    totalCount?: number;

    @serializable(alias("prev_page", primitive()))
    previousPage?: number;

    @serializable(alias("items_per_page", primitive()))
    itemsPerPage?: number;

    @serializable(alias("next_page", primitive()))
    nextPage?: number;

}

export class PaginationMeta{
    @serializable
    page:number = 1;
    @serializable
    isPreviousQuestion:boolean = false
}
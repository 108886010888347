import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';
import React, { FC } from 'react'
import ButtonStyles from "./buttonComponent.module.scss"

interface ButtonComponentProps extends ButtonProps{
    primary?: boolean;
    secondary?: boolean;
    cancel?: boolean;
    previous?:boolean;
    text?: boolean;
    text_icon?: boolean;
    clickHandler?: () => void;
    loading?:boolean,
    name?:string,
    target?:string,
    link?:boolean,
}

const ButtonComponent: FC<ButtonComponentProps> = (props) => {
    const { primary, secondary, text, text_icon, cancel, clickHandler, content,previous, htmlType = "button", className, icon, disabled, size, loading,name,target,href, link} = props;

    return (
            <Button
                loading={!!loading}
                disabled={disabled}
                role={"button"}
                type={link ? 'link' :"default"}
                data-testid={htmlType}
                icon={icon}
                className={clsx(size && ButtonStyles.size,primary && ButtonStyles.primary, secondary && ButtonStyles.secondary, previous && ButtonStyles.previous, cancel && ButtonStyles.cancel, text && ButtonStyles.text, text_icon && ButtonStyles.text_icon,className )}
                htmlType={htmlType}
                onClick={clickHandler}
                name={name}
                target={target}
                href={href}
                >
                {content}
            </Button>
    )
}

export default ButtonComponent;
export const NavigationRoutes = {
  AUTH: "/auth",
  REGISTER: "/auth/register",
  LOGIN: "/auth/login",
};

export const AppRoutes = {
  AUTH: "/auth/*",
  REGISTER: "/register",
  LOGIN: "/login",
  APP_COMPONENTS:"/app-components",
  HOME: "/home",
  LAYOUT:"",
  WELCOME_EMAIL:"/",
  INSTRUCTIONS : "/instructions",
  QUESTIONCARD:"/questions",
  QUESTIONCARD_SPECIFIC:"/questions/:page",
  REPORT:"/report",
  REVIEW_AND_SUBMIT:"/review-and-submit",
  SELECT_LANGUAGE:"/select-language",
  SELECT_SURVEY_SET_TYPE:"/select-survey-set-type"
};

import { RadioChangeEvent } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PaginationMeta } from "../../../models/pagination.meta.model";
import QuestionManagementService from "../../../services/QuestionService/question.service";
import QuestionCard from "../../../shared/components/QuestionCard";
import SharedFooter from "../../../shared/components/SharedFooter";
import QuestionCardStyles from "./questionCard.module.scss";
import useRedirect from "../../../shared/utils/useRedirect";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { useParams } from "react-router-dom";
import { AnswerResourceType, AnswerTypeEnum } from "../../../enums/answerType.enum";
import UserManagementService from "../../../services/UserManagementService/user.service";
import { SurveySteps } from "../../../enums/surveySteps.enum";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import clsx from "clsx";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { AuthContext } from "../../../context/AuthContext";

const QuestionComponent = () => {
  const {page} = useParams();
  const {isCurrentQuestionAvailable} = AuthContext();

  const {
    loading,
    previousQuestionLoading,
    nextQuestionLoading,
    getQuestions,
    questions,
    updateQuestionAnswers,
    pagination,
  } = QuestionManagementService();

  const {loading :submitting, userSurveyUpdate} = UserManagementService();

  const { updateAccessibilityState, selectedTheme, readAloud } = useAccessibility();
  const { redirectToReviewPage } = useRedirect();
  const contentRef = useRef<HTMLDivElement>(null)
  const [progress, setProgress] = useState<number>(0); // Set an initial progress value

  const [questionLoader, setQuestionLoader] = useState(false)

  const [selectedOptions, setSelectedOptions] = React.useState<number[]>(questions?.length && questions[0]?.userSelectedResourceIds || []);

  const [isError, setIsError] = useState(false);
  const [filters, setFilters] = useState<PaginationMeta>({...new PaginationMeta(), page: Number(page) || 1});

  useEffect(() => {
    page &&setFilters({page:Number(page), isPreviousQuestion:true});
  }, [page]);

  useEffect(() => {
    isCurrentQuestionAvailable? getQuestions(filters) : getQuestions({current_question:true});
  }, [filters]);

  useEffect(() => {
    setSelectedOptions(questions?.length && questions[0]?.userSelectedResourceIds || []);
    page && pagination?.totalPages && setProgress(100);
    setQuestionLoader(false)
    updateCurrentQuestionValue();
  }, [questions]);
  
  //Note: if they want to read all content of page ill uncomment this
  // useEffect(() => {
  //   // Check if all loading states are false, indicating that the data is fully loaded
  //   if (loading || previousQuestionLoading || nextQuestionLoading || questionLoader) {
  //     updateAccessibilityState('contentToRead','');
  //   }else {
  //     const content = contentRef?.current?.innerText;
  //     content && updateAccessibilityState('contentToRead',content);
  //   }
    
  // }, [loading, previousQuestionLoading, nextQuestionLoading, questionLoader]);

  const handleOptionChange = (e: RadioChangeEvent) => {
    setSelectedOptions([e.target.value]);
    setIsError(false);
  };

  const updateCurrentQuestionValue = async() => {
    const response = questions?.length && userSurveyUpdate({currentQuestionId: questions[0].id});
    if(!response) return;
  }

  const calculateProgressStep = () => {
    return pagination?.totalPages ? (1 / pagination?.totalPages) * 100 : 0;
  };

  const handleCheckBoxChange = (id: number) => (e: CheckboxChangeEvent) => {
    if (e.target.checked) 
      setSelectedOptions(prev => [...prev, id]);
    else 
      setSelectedOptions(prev => prev.filter(optionId => optionId !== id));

  }
  

  const handlePrevClick = () => {
    // Handle previous button click
    if (pagination?.previousPage !== null) {
      setProgress((prevProgress) =>
        Math.max(0, prevProgress - calculateProgressStep())
      );
      !page  && setFilters({
        page: pagination?.currentPage ? pagination?.currentPage - 1 : 1,
        isPreviousQuestion:true
      });
      setIsError(false);
      updateAccessibilityState('currentQuestion',pagination?.currentPage ? pagination?.currentPage - 1 : 1)
    }
    setQuestionLoader(true)
  };

  const handleNextClick = async() => {
    if (questions?.length && !questions[0].isOptional && !selectedOptions.length) {
      setIsError(true);
      return;
    }else if(questions?.length && questions[0].isOptional && !selectedOptions.length){
      const payload = [{
        questionId: questions?.length && questions[0]?.id,
        resourceId: null,
        resourceType: null
      }]
      const response = await updateQuestionAnswers(payload);
      if(!response) return;
      setQuestionLoader(true)
    }
    else{
      const payload = selectedOptions.map(selectedOption => {
        return{
        questionId: questions?.length && questions[0]?.id,
        resourceId: selectedOption,
        resourceType: questions?.length && questions[0].answerType === AnswerTypeEnum.SINGLE_SELECT_COUNTRY ? AnswerResourceType.COUNTRY : AnswerResourceType.OPTION
      }})
      const response = await updateQuestionAnswers(payload);
      if(!response) return;
      setQuestionLoader(true)
    }
    if (pagination?.nextPage !== null) {
      setProgress((prevProgress) =>
        Math.min(100, prevProgress + calculateProgressStep())
      );
      !page && setFilters({
        page: pagination?.currentPage ? pagination?.currentPage + 1 : 1,
        isPreviousQuestion:false
      });
      updateAccessibilityState('currentQuestion',pagination?.currentPage ? pagination?.currentPage + 1 : 1)
    } else {
      const response = userSurveyUpdate({step: SurveySteps.REVIEW_AND_SUBMIT});
      if(!response) return;
      setProgress(100);
      redirectToReviewPage();
    }
  };

  const handleDropDownChange = (value: number) => {
    setSelectedOptions([value]);
    setIsError(false);
  };


  return (
    <div ref={contentRef} className={QuestionCardStyles.wrapper}>
      <div className={clsx(QuestionCardStyles.container, selectedTheme === ThemeTypes.DARK && QuestionCardStyles.darkTheme, selectedTheme === ThemeTypes.INVERT && QuestionCardStyles.invertTheme )}>
        {questions?.length && questions[0].titlesAttributes?.length &&
        <QuestionCard
          key={questions[0]?.id}
          id={questions[0]?.id}
          title={questions[0].titlesAttributes[0].content || ""}
          options={questions[0]?.optionsAttributes}
          attachments={questions[0]?.questionAttachmentsAttributes}
          isDropDown={questions[0].answerType === AnswerTypeEnum.SINGLE_SELECT_COUNTRY}
          isCheckBox={questions[0].answerType === AnswerTypeEnum.MULTI_SELECT}
          handleOptionChange={handleOptionChange}
          handleCheckBoxChange={handleCheckBoxChange}
          selectedOptions={selectedOptions}
          handleDropDownChange={handleDropDownChange}
          isError={isError}
          loading={previousQuestionLoading || nextQuestionLoading || questionLoader || submitting}
        />}
      </div>
      <SharedFooter
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        progress={Number(progress.toFixed(2))}
        isLastPage={pagination?.nextPage}
        nextQuestionLoading={nextQuestionLoading || submitting}
        previousQuestionLoading={previousQuestionLoading}
        fromReviewPage={!!page}
      />
    </div>
  );
};

export default QuestionComponent;

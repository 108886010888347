import Logo from "../assets/images/nnas-logo.png";
export const dashboardIcon = "icon-Group-78212";
export const reportIcon = "icon-Group-78220";
export const surveyQuestionsIcon = "icon-Group-78214";
export const surveySetIcon = "icon-Group-78213";
export const internalUserIcon = "icon-Group-78218";
export const roleManagementIcon = "icon-Group-78217";
export const backButtonIcon = "icon-angle-left";
export const editIcon = "icon-pencil";
export const trashIcon = "icon-trash";
export const previewIcon = "icon-Icon-awesome-eye";
export const downloadIcon = "icon-downloads";
export const searchInputIcon = "icon-Compound-Path";
export const userAccountIcon = "icon-account";
export const signOutIcon = "icon-sign-out-alt";
export const dropDownIndicatorIcon = "icon-Path-86337";
export const closeIcon = "icon-Path-55379";
export const tickIcon = "icon-Group-787301";
export const warningIcon = "icon-Group-78731";
export const errorIcon = "icon-Group-78732";
export const questionsIcon = "icon-Group-78214";
export const filterIcon = "icon-filter";
export const moreIcon = "icon-Group-77978";
export const selectTickIcon = "icon-Path-148536";
export const noSymbolIcon = "icon-Union-26";
export const uploadIcon = "icon-folder-upload";
export const expandIcon = "icon-Polygon-95";
export const emailIcon = "icon-Layer-2";
export const accessibilityIcon = "icon-Group-78729";

export const dropDownSuffixIcon = "icon-Polygon-95";
export const dragIcon = "icon-Group-78727";
export { Logo };

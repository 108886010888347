import { Spin } from "antd";
import React, { FC } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import LoadingStyles from "./loadingComponent.module.scss";
interface LodingComponentProps{
  title?: string
}
export const LoadingComponent: FC<LodingComponentProps> = ({title}) => {
  return (
    <div className={LoadingStyles.loading__container}>
      <Spin tip={title ? title : "Fetching details..."} indicator={<LoadingOutlined spin/>}/>
    </div>
  );
};

import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { FeedBack, Questions, ReportGenerate, SubmitAnswer } from "../../models/question.model";
import { PaginationModel } from "../../models/pagination.meta.model";
import { AuthContext } from "../../context/AuthContext";

const QuestionManagementService = () => {
  const [error, setError] = useState<Error | unknown>();

  const {updateCurrentQuestion} = AuthContext();

  const [loading, setLoading] = useState(false);

  const [previousQuestionLoading, setPreviousQuestionLLoading] = useState(false);

  const [nextQuestionLoading, setNextQuestionLLoading] = useState(false);

  const [questions, setQuestions] = useState<Questions[]>();

  const [pagination, setPagination] = useState<PaginationModel>();

  const [report, setReport] = useState<ReportGenerate>()

  const [feedback, setFeedback] = useState<FeedBack>()


  const getQuestions = async (params?: { page?: number, isPreviousQuestion?:boolean, pagination?: boolean, current_question?: boolean }) => {
    try {
      if(params?.isPreviousQuestion) setPreviousQuestionLLoading(true);
      else setNextQuestionLLoading(true)
      const response = await axiosInstance.get(ApiRoutes.GET_QUESTION, {
        params,
      });
      if(response.data.questions?.length){
        const questions = deserialize(Questions, response.data.questions) as Questions[];
        setQuestions(questions);
        setPagination(deserialize(PaginationModel, response.data["meta"]));
      }
      if(params?.current_question) updateCurrentQuestion(true);
    } catch (error) {
      setError(error);
    } finally {
      setPreviousQuestionLLoading(false);
      setNextQuestionLLoading(false)
    }
  };
  const updateQuestionAnswers = async (data: SubmitAnswer[]) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(SubmitAnswer, data) as SubmitAnswer[];
      await axiosInstance.put(ApiRoutes.SUBMIT_ANSWER, {user_answers:desiredPayload});
      return true;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const reportGenerate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(ApiRoutes.REPORT_GENERATE);
      const report = deserialize(ReportGenerate, response?.data?.user)
      setReport(report)
      return report?.s3Url
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadReport = async() => {
    const s3Url = await reportGenerate()
    const url = s3Url ? s3Url : "";
    
    try {
      setLoading(true);
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary anchor element
      const a = document.createElement('a');
      const fileUrl = window.URL.createObjectURL(blob);
      
      // Set the href attribute to the object URL
      a.href = fileUrl;
      
      // Specify the file name
      a.download = 'Report.pdf';

      // Append the anchor element to the document body
      document.body.appendChild(a);

      // Trigger a click event to start the download
      a.click();

      // Remove the temporary anchor element from the document
      document.body.removeChild(a);

      // Revoke the object URL to free up resources
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setError(error)
    }finally{
      setLoading(false)
    }
  };

  const getFeedback = async () => {
    try {
      const response = await axiosInstance.get(ApiRoutes.GET_FEEDBACK);
      const desiredData = deserialize(FeedBack, response.data['user_analytic']);
      setFeedback(desiredData)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFeedback = async (data?:FeedBack) => {
    try {
      setLoading(true);
      const desiredPayload = serialize(FeedBack, data) as FeedBack[];
      await axiosInstance.post(ApiRoutes.UPDATE_FEEDBACK, {user_analytic:desiredPayload});
      return true;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  return {
    error,
    loading,
    previousQuestionLoading,
    nextQuestionLoading,
    getQuestions,
    questions,
    updateQuestionAnswers,
    pagination,
    reportGenerate,
    report,
    handleDownloadReport,
    getFeedback,
    feedback,
    updateFeedback
  };
};

export default QuestionManagementService;

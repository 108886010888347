import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import QuestionManagementService from "../../../services/QuestionService/question.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import PdfViewer from "../../../shared/components/PdfViewer";
import SkeltonLoader from "../../../shared/components/SkeltonLoader";
import SuccessCard from "../../../shared/components/SuccessCard";
import ReportStyles from "./report.module.scss";

const SurveyReport = () => {
  const { loading, reportGenerate, report, getFeedback, feedback, updateFeedback } = QuestionManagementService();
  const { selectedTheme } = useAccessibility()
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    reportGenerate();
    getFeedback()
  }, []);
  
  useEffect(()=>{
    if(!feedback?.isAnswered){
      const timeout = setTimeout(() => {
        setShowModal(true);
      }, 150000); // 2.5 minutes in milliseconds
      return () => clearTimeout(timeout);
    }
  },[feedback])

  const handleCloseModal = () => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    updateFeedback({
      analyticCode: feedback?.analyticCode,
      userAnalyticOptionId: undefined
    })
    setShowModal(false)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleUpdate = (id?: number) => {
    id && updateFeedback({
      analyticCode: feedback?.analyticCode,
      userAnalyticOptionId: id
    })
    setShowModal(false)
  }


  return (
    <div className={ReportStyles.pdfContainer}>
      {
        loading ? <SkeltonLoader rows={10} /> : <div className={clsx(ReportStyles.wrapper, selectedTheme === ThemeTypes.DARK && ReportStyles.darkTheme)}>
          {report?.s3Url ? <PdfViewer pdfUrl={report?.s3Url} /> : <></>}
        </div>
      }
      {feedback && <SuccessCard
        visible={showModal}
        onYes={handleUpdate}
        onNo={handleUpdate}
        loading={loading}
        isFeedBack={true}
        handleClose={handleCloseModal()}
        feedbackData={feedback}
        onClose={handleClose}
      />}
    </div>
  );
};

export default SurveyReport;

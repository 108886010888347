import {serializable, alias, primitive,identifier, object,list} from 'serializr';
import { SurveySetType } from '../enums/surveySet.enum';
import { SurveySetLanguageCodeType } from '../enums/surveyLanguage.enum';
import { AttachmentModel } from './attachment.model';
import { LanguagesMeta } from './survey.model';

export class TitleResponse{

    @serializable
    content?: string;

    @serializable
    id?: number;
}

export class Title{

    @serializable
    content?: string;

    @serializable(alias('language_code', primitive()))
    languageCode?: SurveySetLanguageCodeType;

    @serializable(alias('language_id', primitive()))
    languageId?: number;

    @serializable(alias('title_responses_attributes', object(TitleResponse)))
    titleResponseAttributes?: TitleResponse;

    @serializable(alias('resource_type', primitive()))
    resourceType?: string;

    @serializable
    id?: number;

}

export class Options{

    @serializable(alias('titles_attributes', list(object(Title))))
    titlesAttributes?: Title[];

    @serializable
    symbol?: string;

    @serializable
    position?: number;

    @serializable
    id?: number;

    @serializable(alias('number_of_responses', primitive()))
    numberOfResponses?: number;

    @serializable(alias('option_titles', list(object(Title))))
    optionTitles?: Title[];

}
export class CountryDropdownMeta {

    @serializable(alias('name', primitive()))
    label?: string;

    @serializable(alias('id', primitive()))
    value?: string;
}

export class Questions{

    @serializable
    id?: number;

    @serializable(alias('titles_attributes', list(object(Title))))
    titlesAttributes?: Title[];

    @serializable(alias('question_titles', list(object(Title))))
    questionTitles?: Title[];

    @serializable(alias('set_type', primitive()))
    setType?: SurveySetType;

    @serializable(alias('is_optional', primitive()))
    isOptional?: boolean;

    @serializable(alias('question_id', primitive()))
    questionId?: string;

    @serializable
    status?: string;

    @serializable(alias('question_attachments_attributes', list(object(AttachmentModel))))
    questionAttachmentsAttributes?: AttachmentModel[];

    @serializable(alias('answer_type', primitive()))
    answerType?: string;

    @serializable(alias('options_attributes', list(object(Options))))
    optionsAttributes?: Options[];

    @serializable(alias('countries', list(object(CountryDropdownMeta))))
    countries?: CountryDropdownMeta[];

    @serializable(alias('options', list(object(Options))))
    options?: Options[];

    @serializable(alias('updated_at', primitive()))
    updatedAt?: string;

    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('last_activated_at', primitive()))
    lastActivatedAt?: string;

    @serializable(alias('user_selected_resource_ids', list(primitive())))
    userSelectedResourceIds?: number[];

}

export class SubmitAnswer{

    @serializable(alias('question_id', primitive()))
    questionId?: number;

    @serializable(alias('resource_id', primitive()))
    resourceId?: number | null;

    @serializable(alias('resource_type', primitive()))
    resourceType?: string | null;

}



export class ReportGenerate{
    @serializable(alias('s3_url',primitive()))
    s3Url?:string
}

export class FeedBackTitles{
    @serializable
    content?: string;

    @serializable
    id?: number;

    @serializable(alias('language', object(LanguagesMeta)))
    language?: LanguagesMeta;

}

export class FeedBackAnalyticOptions{

    @serializable
    id?: number;

    @serializable(list(object(FeedBackTitles)))
    titles?: FeedBackTitles[];

}

export class FeedBack{

    @serializable(alias('analytic_code', primitive()))
    analyticCode?: string;

    @serializable(alias('is_answered', primitive()))
    isAnswered?: boolean;

    @serializable
    id?: number;

    @serializable(list(object(FeedBackTitles)))
    titles?: FeedBackTitles[];

    @serializable(alias('user_analytic_options', list(object(FeedBackAnalyticOptions))))
    userAnalyticOptions?: FeedBackAnalyticOptions[];

    @serializable(alias('user_analytic_option_id', primitive()))
    userAnalyticOptionId?:number

}

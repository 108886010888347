import { alias, primitive, serializable } from "serializr";


export class AttachmentModel{

    @serializable
    id?:number;

    @serializable(alias('attachment_id', primitive()))
    attachmentId?: number;

    @serializable(alias('filename', primitive()))
    fileName?: string;
    
    @serializable(alias('attachment_type', primitive()))
    attachmentType?: string;

    @serializable(alias('s3_url', primitive()))
    s3Url?: string;

    @serializable(alias('s3_url', primitive()))
    url?: string;

    @serializable(alias('s3_key', primitive()))
    s3Key?: string;

}
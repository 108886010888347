import React from "react";
import { Col, Modal, Row } from "antd";
import Lottie, { Options as LottieOptions } from "react-lottie";
import SuccessMark from "../../../assets/lotties/success-mark.json"; // Replace with the path to your Lottie animation JSON file
import ButtonComponent from "../ButtonComponent";
import SuccessCardStyles from "./successCard.module.scss";
import clsx from "clsx";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { closeIcon } from "../../../constants/icons";
import { FeedBack } from "../../../models/question.model";

interface SuccessCardProps {
  visible: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  className?: string;
  loading?: boolean;
  isFeedBack?: boolean;
  feedbackData?: FeedBack;
  handleClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onYes?: (id?: number) => void;
  onNo?: (id?: number) => void
}

const SuccessCard: React.FC<SuccessCardProps> = ({ visible, onClose, onSubmit, className, loading, isFeedBack, feedbackData, handleClose, onYes }) => {
  const { selectedTheme } = useAccessibility()
  const lottieOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: SuccessMark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleUpdate = (id?: number) => () => {
    id && onYes?.(id)
  }

  const footerContent = [
    <div className={SuccessCardStyles.buttonContainer}>
      <ButtonComponent
        key="close"
        primary
        content="Get your Personlized Checklist"
        className={SuccessCardStyles.button}
        clickHandler={onSubmit}
        loading={loading}
      />
    </div>
  ]
  const feedbackFooter = <div className={SuccessCardStyles.feedbackButtonContainer}>
    <ButtonComponent primary content={feedbackData?.userAnalyticOptions?.[0]?.titles?.[0]?.content} clickHandler={handleUpdate(feedbackData?.userAnalyticOptions?.[0]?.id)} />
    <ButtonComponent content={feedbackData?.userAnalyticOptions?.[1]?.titles?.[0]?.content} clickHandler={handleUpdate(feedbackData?.userAnalyticOptions?.[1]?.id)} />
  </div>


  return (
    <Modal
      className={clsx(isFeedBack ? SuccessCardStyles.feedBackCard : SuccessCardStyles.card, selectedTheme === ThemeTypes.DARK && SuccessCardStyles.darkThemeModal, selectedTheme === ThemeTypes.INVERT && SuccessCardStyles.invertThemeModal)}
      visible={visible}
      footer={isFeedBack ? feedbackFooter : footerContent}
      closeIcon={isFeedBack ? <i className={closeIcon} onClick={handleClose} /> : false}
    >
      {isFeedBack ?
        <div className={SuccessCardStyles.feedbackContainer}>
          <h2 className={SuccessCardStyles.question}>
            {feedbackData?.titles?.[0]?.content}
          </h2>
        </div>
        :
        <div className={SuccessCardStyles.lottiContainer}>
          <Lottie options={lottieOptions} height={150} width={150} />

          <div className={SuccessCardStyles.lottiContainerText}>
            <h1>Success!</h1>
            <p>You have completed the questions</p>
          </div>
        </div>}
    </Modal>
  );
};

export default SuccessCard;

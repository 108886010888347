
export const ApiRoutes = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    USER_LOGIN: "/login",
    USER_MANAGEMENT: "/users_management/user/token",
    GET_QUESTION: "/questions_management/user/questions",
    SUBMIT_ANSWER: "/user_answers_management/user/user_answers",
    REPORT_GENERATE: "/report_management/user/report",
    START_SURVEY: "/user_attempts_management/user/start_survey",
    UPDATE_USER_ATTEMPT: "/user_attempts_management/user/user_attempt",
    END_SURVEY: "/user_attempts_management/user/end_survey",
    USER_VISIT:"/user_analytic_management/user/user_visits",
    GET_FEEDBACK:"/user_analytic_management/user/is_survey_helpful",
    UPDATE_FEEDBACK : "/user_analytic_management/user/user_analytic_responses",

    //META
    SURVEY_SETS_META: "/meta/user/survey_sets",
    LANGUAGES_META: "/meta/user/languages",
    COUNTRIES_META: "/meta/user/countries",
}

import React, { useState, useEffect } from "react";
import { Card, Checkbox, Col, Radio, RadioChangeEvent, Row } from "antd";
import QuestionCardStyles from "./questionCard.module.scss";
import { CountryDropdownMeta, Options } from "../../../models/question.model";
import DropdownField from "../DropdownField";
import { Formik } from "formik";
import parse from "html-react-parser";
import SkeltonLoader from "../SkeltonLoader";
import { AttachmentModel } from "../../../models/attachment.model";
import ButtonComponent from "../ButtonComponent";
import ImagePreview from "../ImagePreview";
import MetaService, { MetaPayloadType } from "../../../services/MetaService/meta.service";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import clsx from "clsx";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { SessionStorage } from "../../utils/localStorageHelpers";
import { SurveySetLanguageType } from "../../../enums/surveyLanguage.enum";
import { DropdownPlaceHolder } from "../../../enums/dropdownPlaceHolder.enum";

interface QuestionCardProps {
  id?: number;
  title?: string;
  options?: Options[];
  handleOptionChange?: (e: RadioChangeEvent) => void;
  selectedOptions?: number [];
  isDropDown?: boolean;
  isCheckBox?: boolean;
  handleDropDownChange?:((value: number, option: CountryDropdownMeta | CountryDropdownMeta[]) => void);
  handleCheckBoxChange?: (id: number) => ((e: CheckboxChangeEvent) => void);
  isError?: boolean;
  loading?: boolean;
  attachments?: AttachmentModel[];
}

type CountryMeta = MetaPayloadType<CountryDropdownMeta>;

const QuestionCard: React.FC<QuestionCardProps> = ({
  title,
  options,
  handleOptionChange,
  selectedOptions,
  isDropDown,
  isCheckBox,
  handleDropDownChange,
  handleCheckBoxChange,
  isError,
  loading,
  attachments
}) => {

  const {fetchMeta} = MetaService();
  const {selectedTheme, updateAccessibilityState, optionsLength} = useAccessibility()

  const [isMoreAttachments, setIsMoreAttachments] = useState<boolean>(false)
  const [countries, setCountries] = useState<CountryDropdownMeta[]>([]);

  const [showPreviewImageModal, setShowPreviewImageModal] =
    useState<boolean>(false);
    const selectedSurveyLanguage = SessionStorage.getItem('SELECTED_LANGUAGE')

  const questionContent = title && parse(title);

  useEffect(()=>{
    isDropDown && fetchMeta({url:ApiRoutes.COUNTRIES_META, responseKey:"countries", setState:setCountries, model:CountryDropdownMeta}as CountryMeta);
    options?.length && updateAccessibilityState('optionsLength',options?.length)
  },[])


  const handleShowMore = () => setIsMoreAttachments((prev) => !prev)

  const viewAttachmentHandler = () => setShowPreviewImageModal((prev) => !prev)


  return (
    <Card className={clsx(QuestionCardStyles.questionCard, selectedTheme === ThemeTypes.DARK && QuestionCardStyles.darkTheme,  selectedTheme === ThemeTypes.INVERT && QuestionCardStyles.invertTheme )}>
      {loading ? (
        <SkeltonLoader />
      ) : (
        <>
          {title && (
            <p className={QuestionCardStyles.question}   data-content-to-read={questionContent}>
              {questionContent}
            </p>
          )}
          {attachments?.length ? (
            <div className={QuestionCardStyles.attachmentContainer}>
              <Row gutter={[8, 16]}>
                {attachments.map(
                  (image, index) => 
                    (isMoreAttachments || index < 5) ?
                        <Col span={4}>
                          <img
                            key={image.id}
                            src={image.url}
                            onClick={viewAttachmentHandler}
                            width={100}
                            height={100}
                          />
                        </Col> :  null 
                )}
                {attachments.length > 5 && (
                  <Col span={4} className={QuestionCardStyles.viewMoreButton}>
                    <ButtonComponent
                      content={isMoreAttachments ? "Show Less" : "Show More"}
                      clickHandler={handleShowMore}
                    />
                  </Col>
                )}
              </Row>
              <ImagePreview
                visible={showPreviewImageModal}
                closeHandler={viewAttachmentHandler}
                title={"Question Attachments"}
                attachment={attachments}
              />
            </div>
          ) : (
            ""
          )}
          <Formik initialValues={{ option: undefined }} onSubmit={() => {}}>
            {isDropDown ? (
              <DropdownField
                name="option"
                options={countries}
                placeholder= {selectedSurveyLanguage === SurveySetLanguageType.ENGLISH ? DropdownPlaceHolder.ENGLISH : DropdownPlaceHolder.FRENCH }
                onChange={handleDropDownChange}
                value={selectedOptions?.length && selectedOptions[0] || undefined}
                className={QuestionCardStyles.dropDown}
              />
            ) : isCheckBox? (
              <div className={QuestionCardStyles.checkboxContainer}>
                {options?.map((option, index) => option.id  && handleCheckBoxChange && (
                  <Checkbox 
                    key={option.id} 
                    checked = {selectedOptions?.includes(option.id)} 
                    onChange={handleCheckBoxChange(option.id)}
                    className={QuestionCardStyles.checkbox}
                    data-content-to-read={option?.titlesAttributes?.length && option?.titlesAttributes[0].content}
                    tabIndex={index + 1}
                    >
                      <p data-content-to-read={option?.titlesAttributes?.length && option?.titlesAttributes[0].content}  >{option?.titlesAttributes?.length && parse(option?.titlesAttributes[0].content || "")}</p>
                  </Checkbox>
                ))}
              </div>
            ) : (
              <Radio.Group onChange={handleOptionChange} value={selectedOptions?.length && selectedOptions[0]} >
                {options?.map((option, index) => (
                  <div data-content-to-read={option?.titlesAttributes?.length && option?.titlesAttributes[0].content} data-question-options tabIndex={index + 2}>
                  <Radio
                    key={`option${index}`}
                    value={option?.id}
                    className={QuestionCardStyles.radioOption}
                    data-content-to-read={option?.titlesAttributes?.length && option?.titlesAttributes[0].content}
                    tabIndex={-1}
                  > 
                    <div data-content-to-read={option?.titlesAttributes?.length && option?.titlesAttributes[0].content} >{option?.titlesAttributes?.length && parse(option?.titlesAttributes[0].content || "")}</div>
                  </Radio>
                  </div>
                ))}
              </Radio.Group>
            )}
          </Formik>
          {isError && (
            <div className={QuestionCardStyles.error} data-content-to-read='Please select an option'>
              Please select an option
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default QuestionCard;

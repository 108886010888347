import axios, { AxiosError, AxiosHeaders } from "axios";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import { SessionStorage } from "../shared/utils/localStorageHelpers";
import { AppRoutes } from "../routes/routeConstants/appRoutes";

interface IRequestQueue {
  request?: any;
  resolve: (val: unknown) => void;
  reject: (reason: unknown) => void;
}

let isTokenValid = true;

const queuedRequests: IRequestQueue[] = [];

const processQueue = (error?: Error, token = "") => {
  queuedRequests.forEach(({ resolve, reject }) =>
    error ? reject(error) : resolve(token)
  );

  queuedRequests.length = 0;
};

export const getHeaders = () => {
  const token = SessionStorage.getItem('TOKEN');
  const headers = Object.assign(new AxiosHeaders, {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": `Bearer ${token ?? ""}`,
  });
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 60000,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { config: originalRequest, response, _retry: RE_TRIED } = error;

    const logout = () => {
      SessionStorage.clear();
      window.location.replace(AppRoutes.WELCOME_EMAIL);
    };

    if (response?.status === 401) {
      if (!!RE_TRIED) return logout();

      if (!isTokenValid) {
        return new Promise((resolve, reject) => {
          queuedRequests.push({ resolve, reject });
        })

          .then((token) => {
            originalRequest.headers["Authorization"] = `bearer ${token}`;

            return axios(originalRequest);
          })

          .catch((ex) => Promise.reject(ex));
      }

      originalRequest._retry = true;
      isTokenValid = false;
      const refreshToken = SessionStorage.getItem("REFRESH_TOKEN") || "";
      const payload = {
        user: {
          grant_type: 'REFRESH_TOKEN',
          refresh_token: refreshToken,
        },
      };
      return axios
        .post(`${ApiRoutes.BASE_URL}/${ApiRoutes.USER_LOGIN}`, payload)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            const { token } = response.data;

            const accessToken = response.data["token"]["access_token"];
            const refreshToken = response.data["token"]["refresh_token"];

            SessionStorage.setItem("ACCESS_TOKEN", accessToken);
            SessionStorage.setItem("REFRESH_TOKEN", refreshToken);
            window.dispatchEvent(
              new StorageEvent("storage", {
                key: "access-token",
                newValue: accessToken,
              })
            );
            window.dispatchEvent(
              new StorageEvent("storage", {
                key: "refresh-token",
                newValue: refreshToken,
              })
            );
            axios.defaults.headers.common["Authorization"] =
              "bearer " + token.access_token;
            originalRequest.headers["Authorization"] =
              "bearer " + token.access_token;
            processQueue(undefined, accessToken);

            return axios(originalRequest);
          }
        })
        .catch((error) => {
          processQueue(error);
          logout();
        })
        .finally(() => {
          isTokenValid = true;
        });
    } else if (response?.status === 422) {
      Notification({
        message: "",
        description: response?.data?.error,
        type: NotificationTypes.ERROR,
      });
    } else if (response?.status === 500) {
      Notification({
        message: "",
        description: "Something went wrong",
        type: NotificationTypes.ERROR,
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
import { Button, Card, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import LandingEmailStyles from "./landingEmail.module.scss";
import InputField from "../../../shared/components/InputField";
import Logo from "../../../../src/assets/images//nnas-logo.png";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import UserManagementService from "../../../services/UserManagementService/user.service";
import { User } from "../../../models/user.model";
import { landingEmailValidationSchema } from "./landingEmailValidationScema";
import useRedirect from "../../../shared/utils/useRedirect";
import clsx from "clsx";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { SessionStorage } from "../../../shared/utils/localStorageHelpers";

enum SubmitButtonType{
  SUBMIT = "submit",
  SKIP = "skip",
} 

const LandingEmail = () => {

  const { loading, emailManagement, userVisited } = UserManagementService()
  const {redirectToSelectLanguage} = useRedirect()
  const { selectedTheme } = useAccessibility()

  const [buttonSubmit, setButtonSubmit] = useState<SubmitButtonType>(SubmitButtonType.SUBMIT);

  useEffect(()=>{
    const sessionToken = SessionStorage.getItem('SESSION_TOKEN')
    if(!sessionToken){
      userVisited()
    }
  },[])
  
  const handleSubmit = async(values: User) => {
    // Handle submit logic here
    setButtonSubmit(SubmitButtonType.SUBMIT)
    await emailManagement({ email: values?.email, isEmail: true })
    redirectToSelectLanguage()
  };
  const handleSkip = async() => {
    // Handle Skip logic here
    setButtonSubmit(SubmitButtonType.SKIP)
    await emailManagement({ isEmail: false })
    redirectToSelectLanguage()
  }

  return (
    <div className={LandingEmailStyles.landingEmail}>
      <Row className={LandingEmailStyles.content}>
        {/* Left Column */}
        <Col xs={24} sm={24} md={12} lg={10} xl={12}>
          <div>
            <img
              src={Logo}
              alt="Logo"
              className={LandingEmailStyles.logo}
              data-content-to-read = 'Logo'
            />
            <h1 className={LandingEmailStyles.landingEmailLogoText} data-content-to-read = 'The starting point for Internaionally Educated Nurses interested in becoming licensed or registered as a nurse in Canada.' 
            >
              The starting point for Internationally Educated Nurses
              interested in becoming licensed or registered as a nurse in
              Canada.
            </h1>
          </div>
        </Col>

        {/* Right Column */}
        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
          <Card className={clsx(LandingEmailStyles.emailCard, selectedTheme === ThemeTypes.DARK && LandingEmailStyles.darkTheme, selectedTheme === ThemeTypes.INVERT && LandingEmailStyles.invertTheme)} >
            <div className={LandingEmailStyles.cardContainer} >
              <h1 className={LandingEmailStyles.emailText} data-content-to-read = 'Enter your email'  
              >Enter your email</h1>
              {/* Formik Form */}
              <Formik
                initialValues={{ email: "" }}
                validationSchema={landingEmailValidationSchema}
                onSubmit={handleSubmit}>
                <Form>
                  {/* Email Input Field */}
                  <div 
                    >
                  <InputField
                    name="email"
                    placeholder="username@example.com"
                    type="email"
                    data-content-to-read='Input field Enter your email'
                  />
                  </div>

                  {/* Submit and Skip Buttons */}
                  <div className={LandingEmailStyles.buttonContainer}>
                    <div className={LandingEmailStyles.submitButtonContainer}>
                    <ButtonComponent
                      primary
                      htmlType="submit"
                      className={LandingEmailStyles.submitButton}
                      content="Submit & Proceed"
                      loading={loading && buttonSubmit === SubmitButtonType.SUBMIT}
                    />
                    </div>
                    <div className={LandingEmailStyles.skipButtonContainer}>
                    <ButtonComponent
                      className={LandingEmailStyles.skipButton}
                      content="Skip >"
                      loading={loading && buttonSubmit === SubmitButtonType.SKIP}
                      clickHandler={handleSkip}
                    />
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LandingEmail;

import React, { useContext, createContext, useMemo, useState, Dispatch, SetStateAction } from "react";
import { SurveySteps } from "../../enums/surveySteps.enum";
import { SessionStorage } from "../../shared/utils/localStorageHelpers";

export interface AuthState {
  token?: string;
  step?: SurveySteps;
  isCurrentQuestionAvailable?: boolean;
}

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState];

// Define the default context state
const initialValues: AuthState = {
  step: SurveySteps.LANDING_EMAIL,
  isCurrentQuestionAvailable: false
};

// Create the context
const AuthContent: any = createContext({});

// Create method to use context
const AuthContext = () => {
  const context = useContext<AuthContentProps>(AuthContent);
  if (!context) {
    throw new Error(`useMeContext must be used within a MeContextProvider`);
  }
  const [auth, setAuth] = context;
  
  const setAuthenticated = (token?: string) => {
    SessionStorage.setItem("TOKEN", token);
    setAuth((auth) => ({
      ...auth,
      token
    }));
  };

  const setStep = (stepValue?: SurveySteps) => {
    setAuth((auth) => ({
      ...auth,
      step: stepValue,
    }));
  };

  const updateCurrentQuestion = (flag:boolean) => {
    setAuth((auth)=>({
      ...auth,
      isCurrentQuestionAvailable: flag
    }))
  }

  return {
    ...auth,
    setAuthenticated,
    setStep,
    updateCurrentQuestion,
  };
};

// Create the context provider
const AuthProvider = (ownProps: any) => {
  const [auth, setAuth] = useState<AuthState>(initialValues);
  const value = useMemo(() => [auth, setAuth], [auth]);
  return <AuthContent.Provider value={value} {...ownProps} />;
}

export { AuthProvider, AuthContext };

import { removeFalsyKeys } from "./removeFalsyKeys";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  generatePath,
} from "react-router-dom";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";

const useRedirect = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const navigateBack = () => navigate(-1);

  const redirect = (pathname: string, queryParams?: {}, hash?:string, replace?: boolean) => {
    navigate(
      {
        pathname,
        search:
          queryParams &&
          createSearchParams(removeFalsyKeys(queryParams)).toString(),
        hash
      },
      { replace }
    );
  };

  const redirectToHome = () => redirect(AppRoutes.HOME);

  const redirectToWelcomeEmail = () => redirect(AppRoutes.WELCOME_EMAIL);

  const redirectToSelectLanguage = () => redirect(AppRoutes.SELECT_LANGUAGE);

  const redirectToSelectSurveySetType= () => redirect(AppRoutes.SELECT_SURVEY_SET_TYPE);

  const redirectToInstructions = () => redirect(AppRoutes.INSTRUCTIONS);

  const redirectToQuestions = (page?:number) => redirect(generatePath(page? AppRoutes.QUESTIONCARD_SPECIFIC: AppRoutes.QUESTIONCARD, {page}));

  const reidirectToReport = () => redirect(AppRoutes.REPORT)

  const redirectToReviewPage = () => redirect(AppRoutes.REVIEW_AND_SUBMIT)

  return {
    location,
    navigate,
    navigateBack,
    redirectToWelcomeEmail,
    redirectToInstructions,
    redirectToQuestions,
    redirectToHome,
    reidirectToReport,
    redirectToReviewPage,
    redirectToSelectLanguage,
    redirectToSelectSurveySetType
  };
};

export default useRedirect;
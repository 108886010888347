import { Progress } from 'antd';
import React, { useEffect, useState } from 'react'
import ButtonComponent from '../ButtonComponent';
import SharedFooterStyles from './sharedFooter.module.scss'
import useRedirect from '../../utils/useRedirect';
import clsx from 'clsx';
import { ThemeTypes } from '../../../enums/accessibilityTheme.enum';
import { useAccessibility } from '../../../context/AccessibilityContext';
import { useParams } from 'react-router-dom';

enum ButtonsType{
    NEXT_BUTTON = "next",
    REVIEW_BUTTON = "review"
}


interface SharedFooterProps {
    onPrevClick?: () => void;
    onNextClick?: () => void;
    progress?: number; // Progress value between 0 and 100
    isLastPage?:number | null;
    previousQuestionLoading?:boolean;
    nextQuestionLoading?:boolean
    reviewPage?: boolean;
    fromReviewPage?: boolean;
}

const SharedFooter: React.FC<SharedFooterProps> = ({ onPrevClick, onNextClick, progress,isLastPage,previousQuestionLoading,nextQuestionLoading, reviewPage, fromReviewPage }) => {

    const {redirectToReviewPage, redirectToQuestions} = useRedirect();
    const {page} = useParams();
    const {selectedTheme, optionsLength} = useAccessibility()

    const [buttonType, setButtonType] = useState<ButtonsType>();

    const handlePreviousClick = () => {
        onPrevClick && onPrevClick();
        page && redirectToQuestions(Number(page)-1);
    }

    const handleNextClick = () => {
        setButtonType(ButtonsType.NEXT_BUTTON);
        onNextClick && onNextClick();
        page && redirectToQuestions(Number(page)+1);
    }

    const handleRedirectToReviewPage = () => {
        setButtonType(ButtonsType.REVIEW_BUTTON);
        onNextClick && onNextClick();
        redirectToReviewPage();
    }

    return (
        <div className={clsx(SharedFooterStyles.sharedFooter, selectedTheme === ThemeTypes.DARK && SharedFooterStyles.darkTheme, selectedTheme === ThemeTypes.INVERT && SharedFooterStyles.invertTheme)}>
            {/* Left side with progress bar */}
            <div className={SharedFooterStyles.progressBarContainer} tabIndex={optionsLength+2} data-content-to-read={`${reviewPage ? 100 : progress}% Complete`}>
                <div className={SharedFooterStyles.progressText} data-content-to-read={`${reviewPage ? 100 : progress}% Complete`}>{reviewPage? 100 : progress}% Complete</div>
                <Progress percent={reviewPage? 100 : progress} status="active" showInfo={false} />
            </div>

            {/* Right side with previous and next buttons */}
            <div className={SharedFooterStyles.buttonContainer}>
                <div className={SharedFooterStyles.previousButtonContainer}>
                {!reviewPage && progress !== 0 &&  Number(page) !== 1 && 
                    <ButtonComponent 
                        className={fromReviewPage ? SharedFooterStyles.linkButton :SharedFooterStyles.previousButton} 
                        content='Previous' 
                        loading={previousQuestionLoading} 
                        clickHandler={handlePreviousClick} 
                        />}
                </div>
                <div className={SharedFooterStyles.nextButtonContainer}>
                <ButtonComponent 
                    className={fromReviewPage?SharedFooterStyles.previousButton:SharedFooterStyles.nextButton} 
                    content={!isLastPage && progress !== 0  ? "Submit" : "Next"} 
                    loading={progress === 0 ? false : buttonType === ButtonsType.NEXT_BUTTON && nextQuestionLoading} 
                    clickHandler={handleNextClick} 
                    />
                </div>
                {fromReviewPage && 
                <div className={SharedFooterStyles.nextButtonContainer}>
                <ButtonComponent 
                    className={SharedFooterStyles.nextButton} 
                    content={"Review Page"} 
                    clickHandler={handleRedirectToReviewPage} 
                    loading={buttonType === ButtonsType.REVIEW_BUTTON && nextQuestionLoading}
                    />
                </div>}
            </div>
        </div>
    );
}

export default SharedFooter
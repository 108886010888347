import React from "react";
import Card from "../../../shared/components/CardComponent";
import InstructionStyles from "./instructionsCard.module.scss";
import Logo from "../../../assets/images/nnas-logo.png";
import useRedirect from "../../../shared/utils/useRedirect";
import UserManagementService from "../../../services/UserManagementService/user.service";
import { useAccessibility } from "../../../context/AccessibilityContext";
import { SurveySetLanguageType } from "../../../enums/surveyLanguage.enum";
import { SessionStorage } from "../../../shared/utils/localStorageHelpers";

const InstructionCard: React.FC = () => {
  const { redirectToQuestions } = useRedirect();
  const { loading, startUserSurvey } = UserManagementService();
  const selectedSurveyLanguage = SessionStorage.getItem('SELECTED_LANGUAGE')

  // Note: Will change once client okay with new points
  
  // const instructionsList = [
  //   `Please make sure you are not interrupted during the test, as the timer
  //       cannot be paused once started.`,
  //   ` Please ensure you have a stable internet connection.`,

  //   `The test will move onto the next question if you click the next button.`,
  //   `The test will move onto the previous question if you click the previous
  //       button.`,
  //   `Language option can't be changed once the survey has been started.`,
  // ];

  const instructionsList = selectedSurveyLanguage === SurveySetLanguageType.ENGLISH ? [
    `NNAS’s Readiness to Apply tool will help prepare you for your NNAS application. The tool will help you understand if you are eligible for NNAS’s services, what documents you will need to provide, and any additional information you will need to disclose on your application.`,
    `NNAS’s Readiness to Apply tool will not assess or verify your credentials in any way or imply any possible Canadian nursing license decision.`,
    `The tool will move onto the next question when you click the next button.`,
    `The tool will move back to the previous question if you click the previous button.`,
    `The language option cannot be changed once you begin to answer the questions.`,
    `If you are interested in completing both the Expedited Service and Regular Service question sets, you will need to complete one at a time.`,
    `If you exit the tool at any point, your responses will not be saved.`,
    `Based on your responses, a personalized checklist will be populated at the end to assess your readiness to begin the NNAS application process. Please download the PDF version of your checklist. Your responses will not be saved once you leave the results page.`,
  ] : 
  [`L'outil de préparation à la candidature de SNEI vous aidera à préparer votre candidature de SNEI. Cet outil vous aidera à comprendre si vous êtes admissible aux services de SNEI, quels documents vous devrez fournir et toute information supplémentaire que vous devrez divulguer dans votre demande.`,
  `L'outil de préparation à la candidature de SNEI n'évalue, ni ne vérifie vos titres de compétences de quelque manière que ce soit et n'implique aucune décision possible concernant l'autorisation d'exercer la profession d'infirmier au Canada.`,
  `L'outil passera à la question suivante lorsque vous cliquerez sur le bouton suivant.`,
  `L'outil reviendra à la question précédente lorsque vous cliquez sur le bouton précédent.`,
  `L'option linguistique ne peut être modifiée une fois que vous avez commencé à répondre aux questions.`,
  `Si vous souhaitez répondre à la fois à la série de questions sur le service accéléré et à la série de questions sur le service régulier, vous devrez répondre à une série de questions à la fois.`,
  `Si vous quittez l'outil à n'importe quel moment, vos réponses ne seront pas sauvegardées.`,
  `En fonction de vos réponses, une liste de contrôle personnalisée sera établie à la fin de l'outil afin d'évaluer si vous êtes prêt à entamer la procédure de candidature de SNEI. Veuillez télécharger la version PDF de votre liste de contrôle. Vos réponses ne seront pas sauvegardées une fois que vous aurez quitté la page des résultats.`]

  const handleRedirectToQuestion = async () => {
    const response = await startUserSurvey();
    if (!response) return;
    redirectToQuestions();
  };

  return (
    <div className={InstructionStyles.wrapper}>
      {/* Note: Logo needs to replace  */}
      <img
        src={Logo}
        alt="Logo"
        className={InstructionStyles.logo}
        data-content-to-read="Logo"
      />
      <Card
        isButton={true}
        buttonText={selectedSurveyLanguage === SurveySetLanguageType.ENGLISH ? "Start Readiness to Apply Tool" : `démarrer l’outil Prêt à postuler`}
        onButtonClick={handleRedirectToQuestion}
        children={
          <>
            <h1
              className={InstructionStyles.instructionHeading}
              data-content-to-read="Instructions"
            >
              Instructions
            </h1>
            <ul className={InstructionStyles.instructions}>
              {instructionsList.map((list, index) => (
                <li key={index}>
                  <p data-content-to-read={list}>{list}</p>
                </li>
              ))}
            </ul>
          </>
        }
        buttonLoading={loading}
      />
    </div>
  );
};

export default InstructionCard;

import React, { useEffect, useRef, useState } from "react";
import QuestionManagementService from "../../../services/QuestionService/question.service";
import SharedFooter from "../../../shared/components/SharedFooter";
import ReviewCardStyles from "./reviewComponent.module.scss";
import parse from "html-react-parser"
import useRedirect from "../../../shared/utils/useRedirect";
import UserManagementService from "../../../services/UserManagementService/user.service";
import SuccessCard from "../../../shared/components/SuccessCard";
import { useAccessibility } from "../../../context/AccessibilityContext";
import clsx from "clsx";
import { ThemeTypes } from "../../../enums/accessibilityTheme.enum";
import { AnswerTypeEnum } from "../../../enums/answerType.enum";
import { SurveySteps } from "../../../enums/surveySteps.enum";
import { LoadingComponent } from "../../../shared/components/LoadingComponent";

const ReviewComponent = () => {
  const {
    previousQuestionLoading,
    nextQuestionLoading,
    getQuestions,
    questions,
  } = QuestionManagementService();
  const {loading, endUserSurvey, userSurveyUpdate} = UserManagementService();
  const {redirectToQuestions, reidirectToReport} = useRedirect();
  const contentRef = useRef<HTMLDivElement>(null)

  const { updateAccessibilityState, selectedTheme, readAloud } = useAccessibility();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const activeElementRef = useRef<HTMLImageElement | HTMLHeadingElement | HTMLElement | Element | null>(null);

  useEffect(() => {
    getQuestions({pagination:false});
  }, []);

  useEffect(()=>{
    questions?.length && updateAccessibilityState('optionsLength',questions?.length)
  },[questions])

  // useEffect(() => {
  //   // Check if all loading states are false, indicating that the data is fully loaded
  //   if ( loading || previousQuestionLoading || nextQuestionLoading ) {
  //     updateAccessibilityState('contentToRead','');
  //   }else {
  //     const content = contentRef?.current?.innerText;
  //     content && updateAccessibilityState('contentToRead',content);
  //   }
    
  // }, [loading, previousQuestionLoading, nextQuestionLoading]);

  const handleSubmit = async() => {
    const response =  await endUserSurvey();
    if(!response) return;
    setIsVisible(true);
  }

  const handleCloseModal = async() => {
		const response = await userSurveyUpdate({step: SurveySteps.VIEW_REPORT});
    if(!response) return;
    setIsVisible(false)
    reidirectToReport()
  }

  const removeActiveClass = ()=> activeElementRef?.current?.classList.remove(ReviewCardStyles.active);

  const handleRedirectToQuestion = (page?:number) => () => redirectToQuestions(page)

  const handleDoubleClick = (content?:string)=>(event:React.MouseEvent<HTMLImageElement | HTMLHeadingElement, MouseEvent>)=> {
    if (readAloud) {
      if (activeElementRef.current) {
        removeActiveClass();
      }
    const targetElement = document.getElementsByClassName(ReviewCardStyles.reviewContentWrapper)
    const target = contentRef?.current?.innerText;
    if (targetElement[0] instanceof Element) {
      targetElement[0].classList.add(ReviewCardStyles.active);
      activeElementRef.current = targetElement[0];
    }
    updateAccessibilityState('contentToRead',target ? target : "")
}
  }

  return (
    <div className={ReviewCardStyles.wrapper} ref={contentRef} >
        <div className={clsx(ReviewCardStyles.reviewContentWrapper, selectedTheme === ThemeTypes.DARK && ReviewCardStyles.darkTheme, selectedTheme === ThemeTypes.INVERT && ReviewCardStyles.invertTheme )} onDoubleClick={handleDoubleClick()}>
          <div className={ReviewCardStyles.reviewTitleBar}>
            <span className={ReviewCardStyles.header}>Review and Submit</span>
            <span className={ReviewCardStyles.headerInfo}>Click the question to edit the answer.</span>
          </div>
          {
            previousQuestionLoading || nextQuestionLoading ? <LoadingComponent /> :
            <div className={ReviewCardStyles.questionsContainer}>
            {questions?.map((question, index) => (
              <div className={ReviewCardStyles.questionCard} key={question.id} onClick={handleRedirectToQuestion(index+1)} tabIndex={index + 2}>
                {question.titlesAttributes?.length && <div className={ReviewCardStyles.questionTitle}>{parse(question.titlesAttributes[0].content || "")}</div>}
                {question.answerType === AnswerTypeEnum.SINGLE_SELECT || question.answerType === AnswerTypeEnum.MULTI_SELECT ? 
                  question.optionsAttributes?.map(option => (
                  <div className={ReviewCardStyles.selectedOption} key={`Option${option.id}`}>{option.titlesAttributes? parse(option.titlesAttributes[0].content || "") :""}</div>
                )): question.answerType === AnswerTypeEnum.SINGLE_SELECT_COUNTRY ?
                  question.countries?.map( country => (
                    <div className={ReviewCardStyles.selectedOption} key={`Country${country.value}`}>
                      {country.label}
                    </div>
                  )):"" }
              </div>
            ))}
          </div>}
        </div>
      <SharedFooter
        onNextClick={handleSubmit}
        nextQuestionLoading={loading}
        reviewPage
      />
      <SuccessCard
        visible={isVisible}
        onSubmit={handleCloseModal}
        loading={loading}
      />
    </div>
  );
};

export default ReviewComponent;

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import { steps } from '../../utils/steps';
import { SurveySteps } from '../../../enums/surveySteps.enum';
import { SessionStorage } from '../../utils/localStorageHelpers';
import UserManagementService from '../../../services/UserManagementService/user.service';
import { questionPageRegex } from '../../../constants/constants';

const RequireAuth = ({ children }: { children: JSX.Element }, stepValue:SurveySteps) => {
    const Authentication = () => {
        const { step, updateCurrentQuestion } = AuthContext();
        const location = useLocation();
        const navigate = useNavigate();
        const {getUserAttempt} = UserManagementService();

        //Step Redirect is used to restrict user to step's route and not manually change it
        const handleStepRedirect = () => {
            const currentStepObject = steps.find(stepConstant => step === stepConstant.step)
            const checkQuestionComp = currentStepObject?.step === SurveySteps.REVIEW_AND_SUBMIT && location.pathname !== AppRoutes.REVIEW_AND_SUBMIT && questionPageRegex.test(location.pathname);
            if(currentStepObject && !checkQuestionComp && !currentStepObject?.allowedRoutes.includes(location.pathname)) currentStepObject?.allowedRoutes && navigate(currentStepObject?.allowedRoutes[0]);
        }

        //Restore Step is done when user reloads, current step is fetched and updated if user has a token
        const handleRestoreStep = async() => {
            const token = SessionStorage.getItem("TOKEN"); 
            const response = token && await getUserAttempt();
            if(!response) return;
        }
        useEffect(() => {
            //When step is not available user is redirected to email page to start get token for survey
            if ((!step) && location.pathname !== AppRoutes.WELCOME_EMAIL) {
                return navigate(AppRoutes.WELCOME_EMAIL);
            }
            if(step === SurveySteps.LANDING_EMAIL) handleRestoreStep();
            else handleStepRedirect();
        }, [step]);

        return children;
    } 

    return <Authentication/>;
};

export const isAllowed = (component: JSX.Element, step: SurveySteps) => {
    return RequireAuth({children: component}, step);
};


export default isAllowed;